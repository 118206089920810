import l1 from './list91.png';
import l2 from './list92.png';
import l3 from './list93.png';
import l4 from './listfin.png';

import './App.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper/modules';
import { useState } from 'react';

function App() {
  const [farengetium, setfarengetium] = useState(0);

  const codeOnInput = async (event) => {
    const accepted = /[^0-9]/;
    const code = event.target.value.toLocaleUpperCase().replace(accepted, "");
    const numb = parseInt(code)
    const formula = 1.8 * numb + 32
    setfarengetium(formula !== "NaN" ? formula : "");
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Градус Фаренгейта<br></br>Артемий Кобзев, 8 "A"</h1>
        <Swiper scrollbar={{hide: true}} spaceBetween={10} navigation={true} modules={[Scrollbar, Navigation]} className="mySwiper">
            <SwiperSlide><img src={l1}/></SwiperSlide>
            <SwiperSlide><img src={l2}/></SwiperSlide>
            <SwiperSlide><img src={l3}/></SwiperSlide>
            <SwiperSlide><div className='gradDiv'><img src={l3}/><div className='CF'><p><input type='text' onInput={codeOnInput}/> °C = {farengetium} °F</p></div></div></SwiperSlide>
            <SwiperSlide><img src={l4}/></SwiperSlide>
        </Swiper>
      </header>
    </div>
  );
}

export default App;
